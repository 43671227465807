import type { GlobalConfig } from '@/types/common-types'
const globalConfig: GlobalConfig = {
  baseUrl: {
    dev: 'http://127.0.0.1:3001/',
    uat: 'http://156.245.25.162:3001/',
    prod: 'https://api.chatsfreely.com/'
  },
  assetsBaseUrl: {
    dev: 'http://127.0.0.1:3001',
    uat: 'http://156.245.25.162:3001',
    prod: 'https://api.chatsfreely.com'
  },
  //配置Socket请求地址
  socketBaseUrl: {
    dev: 'http://127.0.0.1:3001',
    uat: 'http://156.245.25.162:3001',
    prod: 'https://api.chatsfreely.com'
  },
  useI18n: false,
  isOpenVConsole: false,
  debug: false,
  developer: '',
  delayShowLoading: 800,
  version: '1.0.0',
  isOpenCallerModel: true,
  aesConfig: {
    dev: {
      open: false,
      postSecretKey: 'd76a06e91fdb2544',
      getSecretKey: 'fdb12ad76e544906'
    },
    uat: {
      open: true,
      postSecretKey: 'd76a06e91fdb2544',
      getSecretKey: 'fdb12ad76e544906'
    },
    prod: {
      open: true,
      postSecretKey: 'd76a06e91fdb2544',
      getSecretKey: 'fdb12ad76e544906'
    }
  },
  titlePrefix: undefined
}

export default globalConfig
