import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from '@/i18n'

import ViewUIPlus from 'view-ui-plus'
import 'view-ui-plus/dist/styles/viewuiplus.css'

import vant from 'vant'
import 'vant/lib/index.css'
import '@/assets/style/common.less'
import '@/assets/style/qqface.less'

import Vconsole from 'vconsole'

import globalConfig from '@/config/index'
import { deepClone } from '@/libs/utils'

import VirtualList from '@vb_he/vue-virtual-scroll'
import '@vb_he/vue-virtual-scroll/dist/style.css'

console.log('VUE_APP_NAME:', process.env.VUE_APP_NAME)

const app = createApp(App)
app.provide('$globalConfig', globalConfig)
app.provide('$consoleCopy', deepClone(console))
// 这里的代码是用来往全局中注入vconsole工具
app.provide('$vConsole', {})
const vConsole: MyVConsole = new Vconsole() as MyVConsole
vConsole.toggle = (open: boolean) => {
  const vConsoleDom = document.getElementById('__vconsole')
  if (vConsoleDom) {
    if (open) {
      vConsoleDom.style.display = 'block'
    } else {
      vConsoleDom.style.display = 'none'
    }
  }
}

if (process.env.NODE_ENV === 'production') {
  vConsole.toggle(false)
  app.provide('$vConsole', vConsole)
  if (!globalConfig.isOpenVConsole) {
    console.log = () => {
      //
    }
  }
}

if (globalConfig.isOpenVConsole) {
  vConsole.toggle(true)
}

app.use(store).use(router).use(ViewUIPlus).use(vant).use(i18n).use(VirtualList).mount('#app')
