<template>
  <div :class="chatFontName">
    <router-view />
    <audio id="audioMsg" class="hint-audio"></audio>
    <audio id="audioHintMsg" class="hint-audio" preload="auto" :src="newMsgHintMp3"></audio>
    <audio id="audioOffLintHintMsg" class="hint-audio" loop="true" preload="auto" :src="offLintHintMp3"></audio>
    <Modal
      v-model="isShowSocketDisconnectHint"
      :title="t('disconnectionReminder')"
      :footer-hide="true"
      :mask-closable="false"
      :closable="false"
      :mask="true"
    >
      <Alert type="error">
        {{ t('disconnectionReminderTip') }}
      </Alert>
    </Modal>
    <Modal
      v-model="isShowSocketConnectHint"
      :title="t('connectionSucceeded')"
      :footer-hide="true"
      :mask-closable="false"
      :closable="false"
      :mask="true"
    >
      <Alert type="success">{{ t('connectionSucceededTip') }}</Alert>
      <div class="flex al-i-c ju-c-end">
        <Button type="success" @click="palyAllAudioAndClose">{{ t('i.modal.okText') }}</Button>
      </div>
    </Modal>
    <AppMediaPreview
      v-model:is-show="isShowAppMediaPreview"
      :footer-menu="appMediaPreviewFooterMenu"
      :urls="mediaPreviewUrls"
      :current-index="mediaPreviewIndex"
    />
    <div class="right-click-menu-view" :style="rightClickMenuStyle">
      <div class="triangle" :class="triangleType"></div>
      <div v-if="rightClickMenuType === 'msgMenu'" class="msg-menu-view flex al-i-c">
        <div
          v-for="(item, index) in rightClickMenuList"
          :key="index"
          class="fun-item flex"
          @click="rightMenuHandle(item.code)"
        >
          <MyIcon :name="item.icon" color="#fff" />
          <div>{{ item.label }}</div>
        </div>
      </div>
    </div>
    <button id="copyButton" class="copy-button" :data-clipboard-text="clipboardText">{{ t('copy') }}</button>
  </div>
</template>
<script setup lang="ts">
import newMsgHintMp3 from '@/assets/audio/new-msg-hint.mp3'
import offLintHintMp3 from '@/assets/audio/off-line-hint.mp3'

import { ref, watch, computed, inject, nextTick, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import { useI18n } from 'vue-i18n'
import ClipboardJS from 'clipboard'
import { Notice } from 'view-ui-plus'
import { showNotify, showLoadingToast } from 'vant'
// import { franc } from 'franc'

import type { Ref } from 'vue'
import type { GlobalConfig } from '@/types/common-types'
import type { MediaPreviewEvent, MediaPreviewItem } from './types/event-types'

import { COMPANY_SETTING_KEY, SERVER_USER_SETTING_KEY } from '@/constants/index'

import AppMediaPreview from '@/components/app-media-preview.vue'
import MyIcon from '@/components/my-icon.vue'
import { getStorage, getToken, getQueryParameter, cutSkin, throttle, deepClone } from '@/libs/utils'
import { myMessage } from '@/libs/uiAdaptive'
import { emoji2string } from '@/components/my-emoji-picker/emoji-picker'

import SocketClient from '@/libs/socket-client'
import { EventBus } from './eventBus'
import { SERVER_APIS } from '@/apis/server-apis'
import { VISITOR_APIS } from '@/apis/visitor-apis'
import { COMMON_APIS } from '@/apis/common-apis'
import { PLATFORM_APIS } from '@/apis/platform-apis'

const store = useStore()
const currentRoute = useRoute()
const router = useRouter()
const { t } = useI18n()
const globalConfig: GlobalConfig = inject('$globalConfig') as GlobalConfig
const clipboardText = ref()

let _flag = false

// 计算部分
// 字体
const chatFontName = computed(() => {
  const uiSetting = store.getters['_getUserSettingInfo'][SERVER_USER_SETTING_KEY.uiSetting.key]
  if (uiSetting && uiSetting.val && uiSetting.val.fontName) {
    return 'font-' + uiSetting.val.fontName
  } else {
    return 'font-default'
  }
})
// 当前路由
const currentRouteName = computed(() => {
  return currentRoute.name
})

const currentSystemType = computed(() => {
  return store.getters['_getCurrentSystemType']
})

const currentLanguage = computed(() => {
  return store.getters['_getCurrentLanguage']
})

// 监听当前路由名称变化
const stopCurrentRouteNameWatching = watch(currentRouteName, newValue => {
  if (newValue === 'communicateView' && !store.getters['_getSocketClient'] && _flag) {
    const socketClient = new SocketClient({
      path: '/admin-socket/',
      autoConnect: false,
      transports: ['websocket'],
      auth: {
        authorization: 'Bearer ' + getToken()
      }
    })
    store.commit('setSocketClient', socketClient)
    setTimeout(() => {
      socketClient.connect()
    }, 1000)
  }
})
const isShowSocketDisconnectHint: Ref<boolean> = ref(false)
const isShowSocketConnectHint: Ref<boolean> = ref(false)
const isShowAppMediaPreview: Ref<boolean> = ref(false)
const appMediaPreviewFooterMenu: Ref<string[]> = ref([])
const mediaPreviewUrls: Ref<MediaPreviewItem[]> = ref([])
const mediaPreviewIndex: Ref<number> = ref(0)
const rightClickMenuStyle = ref({})
const rightClickMenuType = ref('')
const rightClickMenuList: any = ref([])
const triangleType = ref('')

let newMsgHintAudio: HTMLAudioElement
let offLineHintAudio: HTMLAudioElement

// 显示媒体元素的预览
const toggleMediaPreview = (event: MediaPreviewEvent) => {
  if (event.toggle) {
    isShowAppMediaPreview.value = true
    mediaPreviewUrls.value = event.urls
    mediaPreviewIndex.value = event.currentIndex
    appMediaPreviewFooterMenu.value = event.footerMenu
  } else {
    isShowAppMediaPreview.value = false
    mediaPreviewUrls.value = []
    mediaPreviewIndex.value = 0
    appMediaPreviewFooterMenu.value = []
  }
}

const rightClickMenuData: Ref<any> = ref()
const toggleRightClickMenu = (event: any) => {
  rightClickMenuData.value = event.data
  console.log('rightClickMenuData:', rightClickMenuData.value)

  if (event.open) {
    // 点击其他地方时隐藏自定义菜单
    document.addEventListener('click', function hideContextMenu() {
      rightClickMenuStyle.value = {
        display: 'none'
      }
      document.removeEventListener('click', hideContextMenu)
    })

    // 获取要监听滚动的元素
    const contentElement = document.querySelector('.server-virtual-scroll') as HTMLElement

    // 添加滚动事件监听器
    contentElement.addEventListener('scroll', function scroll() {
      rightClickMenuStyle.value = {
        display: 'none'
      }
      contentElement.removeEventListener('scroll', scroll)
    })

    let left = event.style.left
    let top = event.style.top
    rightClickMenuStyle.value = {
      display: 'block',
      opacity: 0
    }
    triangleType.value = event.style.type
    rightClickMenuType.value = event.type
    rightClickMenuList.value = event.menuList
    nextTick(() => {
      const menuEl = document.querySelector('.right-click-menu-view') as HTMLElement
      if (menuEl) {
        top -= menuEl.getBoundingClientRect().height
        left -= menuEl.getBoundingClientRect().width / 2
        rightClickMenuStyle.value = {
          ...rightClickMenuStyle.value,
          top: top + 'px',
          left: left + 'px',
          opacity: 1
        }
      }
    })
  } else {
    rightClickMenuStyle.value = {
      display: 'none',
      opacity: 0
    }
  }
}

// 右键菜单点击菜单时
const rightMenuHandle = (type: string) => {
  store.commit('setRightClickMsgData', deepClone(rightClickMenuData.value))
  switch (type) {
    case 'withdrawMsg':
      EventBus.emit('WITHDRAW_MESSAGE')
      break
    case 'editMsg':
      EventBus.emit('EDIT_MESSAGE')
      break
    case 'copyMsgText':
      copyTextCallback(rightClickMenuData.value.content)
      break
    case 'translatedText':
      // deepL 翻译需要传一个当前语言
      // 用了franc第三方插件识别，不精准
      // let msgLang = franc(rightClickMenuData.value.content, {
      //   minLength: rightClickMenuData.value.content.length
      // })
      // console.log('msgLang:', msgLang)
      // 换成了openAI的翻译
      translate(rightClickMenuData.value.content, rightClickMenuData.value.id)
      break
    default:
      break
  }
}

const translate = async (text: string, messageId: string) => {
  rightClickMenuData.value.translateInfo = {
    status: 1
  }
  try {
    let res = await COMMON_APIS.openAiTranslate({
      text: text.replace(/<br\s*\/?>/g, ','),
      target_lang: currentLanguage.value || 'EN',
      id: messageId
    })
    rightClickMenuData.value.translateInfo = {
      status: 2,
      result: res.data
    }
  } catch (error) {
    rightClickMenuData.value.translateInfo = {
      status: 3,
      result: []
    }
  }
}

// 页面宽度发生变化时
const windowResize = () => {
  store.commit('setScreenType', document.body.clientWidth)
}

// 监听页面尺寸变化使用节流的方式提升性能
const handleResizeThrottle = throttle(windowResize, 800)

// 新消息播放音频
const playHintAudio = () => {
  if (newMsgHintAudio) {
    newMsgHintAudio.play().catch(function (error) {
      // 捕获播放错误，可能是因为权限问题
      console.error(error)
    })
  }
}

// socket断开连接的公共（客服/客户）回调
const socketDisconnectCallback = () => {
  setTimeout(() => {
    console.log('socket已断开:', currentRouteName.value)
    if (currentRouteName.value !== 'login') {
      isShowSocketDisconnectHint.value = true
      if (offLineHintAudio && offLineHintAudio.paused) {
        offLineHintAudio.play().catch(function (error) {
          // 捕获播放错误，可能是因为权限问题
          console.error(error)
        })
      }
    }
  }, 200)
}

// socket连接成功的公共（客服/客户）回调
// 处理断线的音频暂停还有提示框关闭等逻辑
const socketConnectCallback = () => {
  isShowSocketDisconnectHint.value = false
  isShowSocketConnectHint.value = true
  if (offLineHintAudio && !offLineHintAudio.paused) {
    offLineHintAudio.pause()
  }
}

const palyAllAudioAndClose = () => {
  isShowSocketConnectHint.value = false
  newMsgHintAudio.muted = true
  offLineHintAudio.muted = true
  newMsgHintAudio.play()
  offLineHintAudio.play()
  setTimeout(() => {
    newMsgHintAudio.pause()
    offLineHintAudio.pause()
    newMsgHintAudio.muted = false
    offLineHintAudio.muted = false
  }, 500)
}

// 新版本事件的回调函数
const newVersionCallback = (message: any) => {
  Notice.warning({
    title: '新版本提醒',
    desc: `平台发布了新版本，版本号：${message.msgContent.versionCode}，版本简介：${message.msgContent.versionDesc},刷新页面即可更新！`,
    duration: 0
  })
}

// 复制内容事件的回调函数
const copyTextCallback = (text: string) => {
  const msgContentText = text.replace(/<br\s*\/?>/g, '\n')
  clipboardText.value = emoji2string(msgContentText)
  setTimeout(() => {
    const copyButton: HTMLElement | null = document.querySelector('#copyButton')
    if (copyButton) {
      copyButton.click()
    }
  }, 20)
}

let clipboard: any
onMounted(() => {
  const isDarkMode = getStorage('isDarkMode')

  if (window.flutterBridge) {
    store.commit('setCurrentIsApp', true)
    document.documentElement.classList.add('is-app')
    const statusBarHeight = currentRoute.query['statusHeight'] ?? 0
    document.documentElement.style.setProperty('--app-status-bar-height', statusBarHeight + 'px')
  }

  nextTick(async () => {
    // 如果是黑夜模式
    if (!isDarkMode) {
      cutSkin('light')
    } else {
      cutSkin('dark')
    }

    if (currentSystemType.value === 1) {
      newMsgHintAudio = document.querySelector('#audioHintMsg') as HTMLAudioElement
      offLineHintAudio = document.querySelector('#audioOffLintHintMsg') as HTMLAudioElement
      EventBus.on('PLAY_HINT_AUDIO', playHintAudio)
      EventBus.on('TOGGLE_APP_MEDIA_PREVIEW', toggleMediaPreview)
      EventBus.on('TOGGLE_RIGHT_CLICK_MENU', toggleRightClickMenu)
      EventBus.on('SOCKET_CONNECT_PUBLIC', socketConnectCallback)
      EventBus.on('SOCKET_DISCONNECT_PUBLIC', socketDisconnectCallback)
      EventBus.on('RELEASE_NEW_VERSION', newVersionCallback)

      store.commit('setScreenType', document.body.clientWidth)
      window.addEventListener('resize', handleResizeThrottle)

      // 客服端或用户端
      setTimeout(async () => {
        if (
          currentRouteName.value === 'client' &&
          getQueryParameter('token') &&
          store.getters['_getThirdPartyToken'] !== getQueryParameter('token')
        ) {
          store.commit('setThirdPartyToken', getQueryParameter('token'))
          store.commit('setToken', null)
        }

        if (store.getters['_getToken']) {
          try {
            let userInfoRes = await SERVER_APIS.getUserInfo()
            _flag = true
            if (userInfoRes.userInfo) {
              store.commit('setCurrentUserInfo', userInfoRes.userInfo)
              store.dispatch('getCompanySetting', COMPANY_SETTING_KEY.otherSetting.key).then(() => {
                globalConfig.titlePrefix = store.getters['_getCompanySettingInfo'][COMPANY_SETTING_KEY.otherSetting.key]
                  ? store.getters['_getCompanySettingInfo'][COMPANY_SETTING_KEY.otherSetting.key]['systemName']
                  : ''
              })
              await store.dispatch('getUserSetting', SERVER_USER_SETTING_KEY.uiSetting.key)
              if (!isDarkMode) {
                // 如果不是黑夜模式根据用户设置中的皮肤自动设置对应皮肤
                const uiSetting = store.getters['_getUserSettingInfo'][SERVER_USER_SETTING_KEY.uiSetting.key]
                if (uiSetting && uiSetting.val && uiSetting.val.skin) {
                  cutSkin(uiSetting.val.skin)
                }
              }
              // 如果是客户端
              if (currentRouteName.value === 'client') {
                showLoadingToast({
                  duration: 0,
                  forbidClick: true,
                  message: t('connecting')
                })
                const socketClient = new SocketClient({
                  path: '/web-socket/',
                  autoConnect: false,
                  transports: ['websocket'],
                  auth: {
                    authorization: 'Bearer ' + getToken()
                  }
                })
                store.commit('setSocketClient', socketClient)
                setTimeout(() => {
                  socketClient.connect()
                }, 40)
              } else if (
                currentRouteName.value === 'server' ||
                (currentRoute.matched.length && currentRoute.matched[0].name === 'server')
              ) {
                const socketClient = new SocketClient({
                  path: '/admin-socket/',
                  autoConnect: false,
                  transports: ['websocket'],
                  auth: {
                    authorization: 'Bearer ' + getToken()
                  }
                })
                store.commit('setSocketClient', socketClient)
                setTimeout(() => {
                  socketClient.connect()
                }, 40)
              }
            } else {
              myMessage({
                type: 'danger',
                duration: 0,
                message: t('accountIsInexistence')
              })
            }
          } catch (error) {
            console.log('这里是不是token过期了？', error)
            // myMessage({
            //   type: 'danger',
            //   duration: 5,
            //   message: t('systemError')
            // })
            // token过期 清除token
            store.commit('setToken', null)
            if (currentRouteName.value === 'client') {
              // 企业code
              const companyCode = getQueryParameter('code') || ''
              console.log('企业Code:', companyCode)
              if (companyCode === '') {
                showNotify({
                  type: 'danger',
                  message: '参数错误，错误码：4004'
                })
                return
              }

              const thirdPartyToken = getQueryParameter('token')

              if (thirdPartyToken) {
                let thirdPartyUserInfoRes = await VISITOR_APIS.getThirdPartyUserInfo({
                  token: thirdPartyToken,
                  companyCode: companyCode
                })
                store.commit('setToken', thirdPartyUserInfoRes.data.token)
                store.commit('setCurrentUserInfo', thirdPartyUserInfoRes.data.userInfo)

                const socketClient = new SocketClient({
                  path: '/web-socket/',
                  autoConnect: false,
                  transports: ['websocket'],
                  auth: {
                    authorization: 'Bearer ' + getToken()
                  }
                })
                store.commit('setSocketClient', socketClient)
                setTimeout(() => {
                  socketClient.connect()
                }, 200)
              } else {
                if (globalConfig.isOpenCallerModel) {
                  let visitorTemplate = await VISITOR_APIS.createGuestAccount({
                    companyCode: companyCode
                  })
                  console.log('visitorTemplate:', visitorTemplate)
                  store.commit('setToken', visitorTemplate.data.token)
                  store.commit('setCurrentUserInfo', visitorTemplate.data.userInfo)
                  // showLoadingToast({
                  //   duration: 0,
                  //   forbidClick: true,
                  //   message: t('connecting')
                  // })
                  const socketClient = new SocketClient({
                    path: '/web-socket/',
                    autoConnect: false,
                    transports: ['websocket'],
                    auth: {
                      authorization: 'Bearer ' + getToken()
                    }
                  })
                  store.commit('setSocketClient', socketClient)
                  setTimeout(() => {
                    socketClient.connect()
                  }, 200)
                } else {
                  showNotify({
                    type: 'danger',
                    message: '未开启访客模式'
                  })
                }
              }
            } else if (currentRouteName.value === 'server') {
              Notice.warning({
                title: t('hint'),
                desc: t('authenticationFailed')
              })
              router.replace({
                name: 'login'
              })
            }
          }
        } else {
          if (currentRouteName.value === 'client') {
            // 缓存中没有token，表示游客访问，通过地址栏获取token
            // 如果有token，表示第三方平台已登录，用这个token调用第三方平台的获取用户信息接口然后和客服数据库中的用户列表对比是否存在
            // 如果链接中存在token，直接返回对应的用户id的客服平台的token
            // 如果链接中不存在token，判断是否开启了访客访问模式，是则调用创建客服平台用户的接口并生成客服平台的token，没开启则弹出提示
            const thirdPartyToken = getQueryParameter('token')
            console.log('thirdPartyToken:', thirdPartyToken)
            const companyCode = getQueryParameter('code') || ''
            if (thirdPartyToken) {
              showLoadingToast({
                duration: 0,
                forbidClick: true,
                message: t('connecting')
              })
              // 企业code
              let thirdPartyUserInfoRes = await VISITOR_APIS.getThirdPartyUserInfo({
                token: thirdPartyToken,
                companyCode: companyCode
              })
              store.commit('setToken', thirdPartyUserInfoRes.data.token)
              store.commit('setCurrentUserInfo', thirdPartyUserInfoRes.data.userInfo)

              const socketClient = new SocketClient({
                path: '/web-socket/',
                autoConnect: false,
                transports: ['websocket'],
                auth: {
                  authorization: 'Bearer ' + getToken()
                }
              })
              store.commit('setSocketClient', socketClient)
              setTimeout(() => {
                socketClient.connect()
              }, 200)
            } else {
              if (globalConfig.isOpenCallerModel) {
                let visitorTemplate = await VISITOR_APIS.createGuestAccount({
                  companyCode: companyCode
                })
                console.log('visitorTemplate:', visitorTemplate)
                store.commit('setToken', visitorTemplate.data.token)
                store.commit('setCurrentUserInfo', visitorTemplate.data.userInfo)
                showLoadingToast({
                  duration: 0,
                  forbidClick: true,
                  message: t('connecting')
                })
                const socketClient = new SocketClient({
                  path: '/web-socket/',
                  autoConnect: false,
                  transports: ['websocket'],
                  auth: {
                    authorization: 'Bearer ' + getToken()
                  }
                })
                store.commit('setSocketClient', socketClient)
                setTimeout(() => {
                  socketClient.connect()
                }, 200)
              } else {
                showNotify({
                  type: 'danger',
                  message: '未开启访客模式'
                })
              }
            }
          } else if (currentRouteName.value === 'server') {
            Notice.warning({
              title: t('hint'),
              desc: t('authenticationFailed')
            })
            router.replace({
              name: 'login'
            })
          }
        }
      }, 500)
    } else if (currentSystemType.value === 2) {
      // 平台端
      if (store.getters['_getToken']) {
        try {
          let userInfoRes = await PLATFORM_APIS.getUserInfo()
          if (userInfoRes.userInfo) {
            store.commit('setCurrentUserInfo', userInfoRes.userInfo)
          } else {
            Notice.warning({
              title: t('hint'),
              desc: t('accountIsInexistence')
            })
          }
        } catch (error) {
          console.log('error:', error)
          Notice.warning({
            title: t('hint'),
            desc: t('systemError')
          })
        }
      } else if (currentRouteName.value === 'platform') {
        Notice.warning({
          title: t('hint'),
          desc: t('authenticationFailed')
        })
        router.replace({
          name: 'login'
        })
      }
    } else {
      console.log('非法类型')
    }

    EventBus.on('COPY_TEXT', copyTextCallback)
    clipboard = new ClipboardJS('#copyButton')

    clipboard.on('success', (e: any) => {
      myMessage({
        type: 'primary',
        message: t('copySuccess')
      })
      e.clearSelection()
    })
    clipboard.on('error', (e: any) => {
      myMessage({
        type: 'danger',
        message: t('copyFail')
      })
      e.clearSelection()
    })
  })
})
onUnmounted(() => {
  clipboard.destroy()
  stopCurrentRouteNameWatching()
  EventBus.off('PLAY_HINT_AUDIO', playHintAudio)
  EventBus.off('TOGGLE_APP_MEDIA_PREVIEW', toggleMediaPreview)
  EventBus.off('TOGGLE_RIGHT_CLICK_MENU', toggleRightClickMenu)
  EventBus.off('SOCKET_CONNECT_PUBLIC', socketConnectCallback)
  EventBus.off('SOCKET_DISCONNECT_PUBLIC', socketDisconnectCallback)
  EventBus.off('COPY_TEXT', copyTextCallback)
  EventBus.off('RELEASE_NEW_VERSION', newVersionCallback)

  window.removeEventListener('resize', handleResizeThrottle)
})
</script>
<style lang="less">
// @import url('https://at.alicdn.com/t/c/font_4375418_bxtr8ij4shc.css');
@import url('https://at.alicdn.com/t/c/font_4375418_vhrgk9al64g.css');
#app {
  min-height: 100vh;
}

.copy-button {
  position: fixed;
  left: -99999px;
  opacity: 0;
}

.right-click-menu-view {
  position: fixed;
  display: none;
  border-radius: 5px;
  padding: 10px;
  background-color: #555;
  z-index: 100;

  .triangle {
    position: absolute;
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    left: 50%;
    transform: translateX(-50%);

    &.top {
      border-bottom: 6px solid #555;
      top: -6px;
    }

    &.bottom {
      border-top: 6px solid #555;
      bottom: -6px;
    }
  }

  .msg-menu-view {
    .fun-item {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #fff;
      padding: 0 10px;
      font-size: 12px;
      flex: 1 0 auto;
    }
  }
}
</style>
