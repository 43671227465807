import HttpRequest from '@/libs/http-instance'
import globalConfig from '@/config/index'
const env: 'dev' | 'uat' | 'prod' = process.env.VUE_APP_NAME
const baseUrl: string = globalConfig.baseUrl[env]
const axiosServer: HttpRequest = new HttpRequest(baseUrl)

import type {
  GetUserInfoRes,
  GetMessageReq,
  GetMessageRes,
  UpdateMsgReq,
  UpdateMsgRes,
  GetTeamListRes,
  GetTeamConversationReq,
  GetTeamConversationRes,
  GetUserListReq,
  GetUserListRes,
  GetServerUserSettingReq,
  GetServerUserSettingRes,
  PostServerUserSettingReq,
  PostServerUserSettingRes,
  DeleteServerUserSettingReq,
  DeleteServerUserSettingRes,
  UpdateConversationReq,
  UpdateConversationRes,
  UpdateConversationRetransmissionReq,
  UpdateConversationRetransmissionRes,
  UpdateCompanyInfoByLogoReq,
  GetCompanySettingReq,
  GetCompanySettingRes,
  PostCompanySettingReq,
  PostCompanySettingRes,
  DeleteCompanySettingReq,
  DeleteCompanySettingRes
} from '@/types/apis-types/server-apis-types'

import type { LoginReq, LoginRes, CommApiRes } from '@/types/apis-types/common-apis-types'

interface ServerApis {
  /**
   * 登录接口
   * */
  login(data: LoginReq): Promise<LoginRes>
  /**
   * 获取用户信息
   * */
  getUserInfo(): Promise<GetUserInfoRes>
  /**
   * 获取会话对应的消息
   * */
  getMessagesForConversationId(data: GetMessageReq): Promise<GetMessageRes>
  /**
   * 更新消息为已读
   * */
  updateMsgInfo(data: UpdateMsgReq): Promise<UpdateMsgRes>
  /**
   * 更新指定会话的所有消息为已读
   * */
  updateMsgInfoToConversation(data: { conversationId: string | number }): Promise<UpdateMsgRes>
  /**
   * 更新会话状态
   * */
  updateConversationStatus(data: UpdateConversationReq): Promise<UpdateConversationRes>
  /**
   * 更新会话转发
   * */
  updateConversationRetransmission(
    data: UpdateConversationRetransmissionReq
  ): Promise<UpdateConversationRetransmissionRes>
  /**
   * 获取团队列表
   * */
  getTeamList(): Promise<GetTeamListRes>
  /**
   * 获取团队中某个客服的会话
   * */
  getTeamConversation(data: GetTeamConversationReq): Promise<GetTeamConversationRes>
  /**
   * 获取客服账号列表
   * */
  getUserList(data: GetUserListReq): Promise<GetUserListRes>
  /**
   * 创建客服账号
   * */
  creatOrUpdateUser(data: any): Promise<any>
  /**
   * 删除客服账号
   * */
  deleteUser(data: any): Promise<any>
  /**
   * 获取角色列表
   * */
  getRoleList(data: any): Promise<any>
  /**
   * 创建角色或更新角色
   * */
  creatOrUpdateRole(data: any): Promise<any>
  /**
   * 删除角色
   * */
  deleteRole(data: any): Promise<any>
  /**
   * 获取客服类型列表
   * */
  getServerTypeList(data: any): Promise<any>
  /**
   * 创建或更新客服类型
   * */
  creatOrUpdateServerType(data: any): Promise<any>
  /**
   * 删除客服类型
   * */
  deleteServerType(data: any): Promise<any>
  /**
   * 获取客服用户设置
   * */
  getServerUserSetting(data: GetServerUserSettingReq): Promise<GetServerUserSettingRes>
  /**
   * 创建或更新客服用户设置
   * */
  creatOrUpdateServerUserSetting(data: PostServerUserSettingReq): Promise<PostServerUserSettingRes>
  /**
   * 删除客服用户设置
   * */
  deleteServerUserSetting(data: DeleteServerUserSettingReq): Promise<DeleteServerUserSettingRes>
  /**
   * 更新管理员归属的企业LOGO地址
   * */
  updateCompanyInfoByLogo(data: UpdateCompanyInfoByLogoReq): Promise<CommApiRes>
  /**
   * 获取企业设置
   * */
  getCompanySetting(data: GetCompanySettingReq): Promise<GetCompanySettingRes>
  /**
   * 创建或更新企业设置
   * */
  creatOrUpdateCompanySetting(data: PostCompanySettingReq): Promise<PostCompanySettingRes>
  /**
   * 删除企业设置
   * */
  deleteCompanySetting(data: DeleteCompanySettingReq): Promise<DeleteCompanySettingRes>
  /**
   * @description: 拉黑/解除拉黑
   * @return {*}
   */
  setBlackUserForId(data: { id: number; isBlack: boolean; conversationId: number }): Promise<any>
  /**
   * 设置用户备注
   * */
  setRemarkValue(data: any): Promise<any>
  /**
   * @description: 重新排队
   * @return {*}
   */
  reQueueConversation(data: any): Promise<any>
}

export const SERVER_APIS: ServerApis = {
  login(data) {
    return axiosServer.request({
      url: '/admin/login',
      method: 'post',
      data,
      showNotify: true
    })
  },
  getUserInfo() {
    return axiosServer.request({
      url: '/admin/getUserInfo',
      method: 'get',
      showNotify: true
    })
  },
  getMessagesForConversationId(params) {
    return axiosServer.request({
      url: '/admin/getMessagesForConversationId',
      method: 'get',
      params,
      showNotify: true
    })
  },
  updateMsgInfo(data) {
    return axiosServer.request({
      url: '/admin/updateMsgInfo',
      method: 'post',
      data,
      showNotify: true
    })
  },
  updateMsgInfoToConversation(data) {
    return axiosServer.request({
      url: '/admin/updateMsgInfoToConversation',
      method: 'post',
      data,
      showNotify: true
    })
  },
  updateConversationStatus(data) {
    return axiosServer.request({
      url: '/admin/updateConversationStatus',
      method: 'post',
      data,
      showNotify: true
    })
  },
  updateConversationRetransmission(data) {
    return axiosServer.request({
      url: '/admin/updateConversationRetransmission',
      method: 'post',
      data,
      showNotify: true
    })
  },
  getTeamList() {
    return axiosServer.request({
      url: '/admin/getTeamList',
      method: 'get',
      showNotify: true
    })
  },
  getTeamConversation(params) {
    return axiosServer.request({
      url: '/admin/getTeamConversation',
      method: 'get',
      params,
      showNotify: true
    })
  },
  getUserList(params) {
    return axiosServer.request({
      url: '/admin/getUserList',
      method: 'get',
      params,
      showNotify: true
    })
  },
  creatOrUpdateUser(data) {
    return axiosServer.request({
      url: '/admin/creatOrUpdateUser',
      method: 'post',
      data,
      showNotify: true
    })
  },
  deleteUser(data) {
    return axiosServer.request({
      url: '/admin/deleteUser',
      method: 'post',
      data,
      showNotify: true
    })
  },
  getRoleList(params) {
    return axiosServer.request({
      url: '/admin/getRoleList',
      method: 'get',
      params,
      showNotify: true
    })
  },
  creatOrUpdateRole(data) {
    return axiosServer.request({
      url: '/admin/creatOrUpdateRole',
      method: 'post',
      data,
      showNotify: true
    })
  },
  deleteRole(data) {
    return axiosServer.request({
      url: '/admin/deleteRole',
      method: 'post',
      data,
      showNotify: true
    })
  },
  getServerTypeList(params) {
    return axiosServer.request({
      url: '/admin/getServerTypeList',
      method: 'get',
      params,
      showNotify: true
    })
  },
  creatOrUpdateServerType(data) {
    return axiosServer.request({
      url: '/admin/creatOrUpdateServerType',
      method: 'post',
      data,
      showNotify: true
    })
  },
  deleteServerType(data) {
    return axiosServer.request({
      url: '/admin/deleteServerType',
      method: 'post',
      data,
      showNotify: true
    })
  },
  getServerUserSetting(params) {
    return axiosServer.request({
      url: '/admin/getServerUserSetting',
      method: 'get',
      params,
      showNotify: true
    })
  },
  creatOrUpdateServerUserSetting(data) {
    return axiosServer.request({
      url: '/admin/creatOrUpdateServerUserSetting',
      method: 'post',
      data,
      showNotify: true
    })
  },
  deleteServerUserSetting(data) {
    return axiosServer.request({
      url: '/admin/deleteServerUserSetting',
      method: 'post',
      data,
      showNotify: true
    })
  },
  updateCompanyInfoByLogo(data) {
    return axiosServer.request({
      url: '/admin/updateCompanyInfoByLogo',
      method: 'post',
      data,
      showNotify: true
    })
  },
  getCompanySetting(params) {
    return axiosServer.request({
      url: '/admin/getCompanySetting',
      method: 'get',
      params,
      showNotify: true
    })
  },
  creatOrUpdateCompanySetting(data) {
    return axiosServer.request({
      url: '/admin/creatOrUpdateCompanySetting',
      method: 'post',
      data,
      showNotify: true
    })
  },
  deleteCompanySetting(data) {
    return axiosServer.request({
      url: '/admin/deleteCompanySetting',
      method: 'post',
      data,
      showNotify: true
    })
  },
  setBlackUserForId(data) {
    return axiosServer.request({
      url: '/admin/setBlackUserForId',
      method: 'post',
      data,
      showNotify: true
    })
  },
  setRemarkValue(data) {
    return axiosServer.request({
      url: '/admin/updateConversationRemark',
      method: 'post',
      data,
      showNotify: true
    })
  },
  reQueueConversation(data) {
    return axiosServer.request({
      url: '/admin/reQueueConversation',
      method: 'post',
      data,
      showNotify: true
    })
  }
}
